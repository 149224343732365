import { View } from '@aws-amplify/ui-react';
import BgBottomLeft from 'assets/images/BgBottomLeft.svg';
import BgTopCenter from 'assets/images/BgTopCenter.svg';
import { LottiePlayer, LottieWrapper } from '@/components/Lottie';
import GradientLottie from 'assets/lottie/data.json';
import { FC } from 'react';

interface BackgroundHeroProps {
  height?: string;
}

export const BackgroundHero: FC<BackgroundHeroProps> = ({ height }) => {
  return (
    <>
      <View
        position="absolute"
        width="100%"
        height={height ? height : '100vh'}
        top="0"
        style={{ zIndex: '-2', backgroundImage: 'linear-gradient(var(--blue-calm), var(--white))' }}
      >
        <View position="absolute" left="0" bottom="0" style={{ zIndex: '-1' }}>
          <BgBottomLeft />
        </View>
        <View position="absolute" left="30%" top="0" style={{ zIndex: '-1' }}>
          <BgTopCenter />
        </View>
      </View>
      <LottieWrapper marginRight="-30%" top="-600px" right="24px">
        <LottiePlayer renderer="svg" autoplay loop src={GradientLottie} />
      </LottieWrapper>
    </>
  );
};
