import React, { FC } from 'react';
import styled from 'styled-components';

const Icon = styled.div`
  width: 100px;
  height: 100px;
  padding: 30px;
  border-radius: 32px;
`;

export const IconContainer: FC<{
  IconComponent: React.JSXElementConstructor<React.SVGProps<SVGSVGElement>>;
}> = ({ IconComponent }) => {
  return (
    <Icon className="iconCard">
      <IconComponent />
    </Icon>
  );
};
