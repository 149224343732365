import React, { FC } from 'react';
import { Flex, FlexProps } from '@aws-amplify/ui-react';

interface PageLayoutProps extends FlexProps {
  children: React.ReactNode;
}

const PageLayout: FC<PageLayoutProps> = ({
  children,
  paddingTop,
  alignItems,
  justifyContent,
  overflow,
  paddingBottom,
}) => {
  return (
    <Flex
      paddingTop={paddingTop}
      alignItems={alignItems}
      justifyContent={justifyContent}
      paddingBottom={paddingBottom}
      position="relative"
      overflow={overflow ? overflow : 'hidden'}
      direction="column"
      height="100%"
      minHeight="100vh"
    >
      {children}
    </Flex>
  );
};

export default PageLayout;
