import dynamic from 'next/dynamic';
import React, { FC } from 'react';

const NonSSRWrapper: FC<{
  children: React.ReactNode;
}> = (props) => <>{props.children}</>;

export default dynamic(() => Promise.resolve(NonSSRWrapper), {
  ssr: false,
});
