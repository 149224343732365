import { Player } from '@lottiefiles/react-lottie-player';
import { View } from '@aws-amplify/ui-react';
import styled from 'styled-components';

export const LottiePlayer = styled(Player)`
  width: 100%;
`;

export const LottieWrapper = styled(View)`
  position: absolute;
  z-index: -1;
`;
