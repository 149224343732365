import { View } from '@aws-amplify/ui-react';
import styled from 'styled-components';

export const Card = styled(View)`
  display: flex;
  flex-direction: column;
  height: 320px;
  width: ${(props) => (props.width ? props.width : '397px')};
  margin-right: 32px;
  margin-bottom: 24px;
  border-radius: 25px;
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 20px 40px 0px #566e9b14;
  transition: box-shadow 300ms ease, transform 300ms ease, -webkit-transform 300ms ease;

  :last-child,
  :nth-last-child(2) {
    margin-bottom: 0;
  }

  &:hover {
    box-shadow: 0px 48px 44px 0px #566e9b10;
    transform: translate(0px, -8px);
  }

  .iconCard {
    background-color: #e6f4ff3d;
    transition: background-color 300ms ease;
  }

  &:hover .iconCard {
    background-color: #377dff;
    .hovered {
      fill: #ffffff;
    }
  }
`;
