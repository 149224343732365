import { View } from "@aws-amplify/ui-react";
import styled from 'styled-components';

export const CommentCard = styled(View)`
 display: flex;
 flex-direction: column;
 height: 320px;
 width: 500px;
 margin-right: 24px;
 margin-bottom: 24px;
 border-radius: 25px;
 padding: 30px;
 background: #ffffff;
 box-shadow: 0px 20px 40px 0px #566E9B14;
 transition: box-shadow 300ms ease, transform 300ms ease, -webkit-transform 300ms ease;

 :last-child {
   margin-right: 0;
 }

 &:hover {
  cursor: pointer;
  box-shadow: 0px 48px 44px 0px #566e9b10;
  transform: translate(0px, -8px);
 }

 .iconCard {
   background-color: #E6F4FF3D;
   transition: background-color 300ms ease;
 }

 &:hover .iconCard {
  background-color: #377DFF;
 }
`