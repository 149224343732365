/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Text, View } from "@aws-amplify/ui-react";
import NoSSR from "components/NoSSR";
export default function PointFeature(props) {
  const { children, overrides, ...rest } = props;
  return (
    <NoSSR>
    <View
      width="58px"
      height="18px"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "PointFeature")}
    >
      <Text
        fontFamily="Alphasans"
        fontSize="14px"
        label="DIGITAL"
        fontWeight="800"
        color="rgba(48,209,88,1)"
        lineHeight="16.40625px"
        textAlign="left"
        display="flex"
        direction="column"
        justifyContent="flex-start"
        position="absolute"
        top="0%"
        bottom="0%"
        left="0%"
        right="0%"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={children}
        {...getOverrideProps(overrides, "ANALYTICS")}
      ></Text>
    </View>
    </NoSSR>
  );
}
