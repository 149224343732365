import { Flex, View } from '@aws-amplify/ui-react';
import { FC } from 'react';
import styled from 'styled-components';

const PricingWrapper = styled(View)`
  display: flex;
  flex-direction: column;
  width: 300px;
  margin-right: 24px;
  border-top-left-radius: ${(props) => (props.popular ? '0 !important' : '16px')};
  border-top-right-radius: ${(props) => (props.popular ? '0 !important' : '16px')};
  border: ${(props) => (props.popular ? '4px solid #015DFE' : '')};
  border-top: 0;
  border-radius: 16px;
  padding: 32px 16px;
  background: #ffffff;
  box-shadow: 0px 8px 40px 0px #566e9b14;
  transition: box-shadow 300ms ease, transform 300ms ease, -webkit-transform 300ms ease;

  :last-child {
    margin-right: 0;
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0px 48px 44px 0px #566e9b10;
    transform: translate(0px, -8px);
  }
`;

const Popular = styled(View)`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-weight: 700;
  background-color: #015dfe;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`;

export const PricingCard: FC<{
  children: React.ReactNode;
  popular?: boolean;
}> = ({ children, popular = false }) => {
  return (
    <Flex justifyContent="flex-end" direction="column" style={{ gap: 0 }}>
      {popular ? (
        <>
          <Popular>Most Popular</Popular>
          <PricingWrapper popular={popular}>{children}</PricingWrapper>
        </>
      ) : (
        <PricingWrapper popular={popular}>{children}</PricingWrapper>
      )}
    </Flex>
  );
};
